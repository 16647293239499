
<template>
  <div v-if="loading" class="text-center container-fluid" style="font-size: 32px">
    <font-awesome-icon icon="fa-solid fa-sync fa-10x" spin/>
  </div>
  <div v-else>
    <br/>
  <div class="card col-xl-6 col-lg-6 col-md-8 col-sm-12 justify-content-center container-fluid">
    <h5  class="card-title text-center mt-3">Edit user #{{user.id}}</h5>
    <form @submit.prevent="updateUser">
    <div class="card-body">
      <div class="alert alert-success" role="alert" v-if="successEditUser" >
        {{ successEditUser}}
      </div>
      <div class="alert alert-success" role="alert" v-if="passwordRestore" @click="copyToClipboard">
        {{ passwordRestore}} {{password}}
      </div>
      <div class="alert alert-success" role="alert" v-if="successForgotten">
        {{ successForgotten}} <a :href="'mailto:' + user.email">{{user.email}}</a>
      </div>
      <div class="accordion" id="accordionEditUser">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingEditUserOne">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEditUser" aria-expanded="true" aria-controls="collapseEditUser">
              User general info
            </button>
          </h2>
          <div id="collapseEditUser" class="accordion-collapse collapse" aria-labelledby="headingEditUserOne" data-bs-parent="#accordionEditUser">
            <div class="accordion-body">
              <div class="mb-3">
                <label for="name" class="form-label">Name</label>
                <input type="text" class="form-control" id="name" v-model="user.name" aria-describedby="nameHelp">
                <p v-if="validationErrors" v-for="names in validationErrors.name" class="text-danger">{{ names}}</p>
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">Email address</label>
                <input type="email" class="form-control" id="exampleInputEmail1" v-model="user.email" aria-describedby="emailHelp">
                <p v-if="validationErrors" v-for="email in validationErrors.email" class="text-danger">{{ email}}</p>
              </div>
              <div class="mb-3">
                <label for="email-verified" class="form-label">Email verified at</label>
                <input type="datetime-local" class="form-control" id="email-verified" v-model="user.email_verified_at" aria-describedby="emailHelp">
                <p v-if="validationErrors" v-for="emailVerifiedAt in validationErrors.email_verified_at" class="text-danger">{{ emailVerifiedAt}}</p>
              </div>
              <div class="mb-3">
                <label for="phone-number" class="form-label">Phone number</label>
                <input type="tel" class="form-control" id="phone-number" v-model="user.phone_number" aria-describedby="phoneHelp">
                <p v-if="validationErrors" v-for="phone_number in validationErrors.phone_number" class="text-danger">{{ phone_number}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer justify-content-center d-flex flex-wrap gap-1">
        <button type="submit" class="btn btn-primary" :disabled="disableEditUser">
          <span v-if="!disableEditUser">Submit</span>
          <span v-else><font-awesome-icon icon="fa-solid fa-sync" spin/></span>
        </button>
      </div>

      <div class="card-footer justify-content-center d-flex flex-wrap gap-1">
        <button type="button" class="btn btn-warning" @click="restorePassword" :disabled="disableGenerate">
          <span v-if="!disableGenerate">Generate new password</span>
          <span v-else><font-awesome-icon icon="fa-solid fa-sync" spin/></span>
        </button>
        <button type="button" class="btn btn-success" @click="sendForgottenPassword" :disabled="disableSend">
          <span v-if="!disableSend">Send password reset email</span>
          <span v-else><font-awesome-icon icon="fa-solid fa-sync" spin/></span>
        </button>
      </div>
    </div>
    </form>
    </div>
  <div class="card col-xl-6 col-lg-6 col-md-8 col-sm-12 justify-content-center container-fluid">
    <h5 class="card-title text-center mt-3">Update mail notifications</h5>
    <form @submit.prevent="updateMailNotifications">
    <div class="card-body">
      <div class="alert alert-success" role="alert" v-if="successEditMail">
        {{ successEditMail}}
      </div>
      <div class="accordion" id="accordionEditMailNotifications">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingEditMailNotifications">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEditMailOne" aria-expanded="false" aria-controls="collapseEditMailOne">
              Mail notifications
            </button>
          </h2>
          <div id="collapseEditMailOne" class="accordion-collapse collapse" aria-labelledby="headingEditMailNotifications" data-bs-parent="#accordionEditMailNotifications">
            <div class="accordion-body gaps-accordion">
              <div class="mb-3">
                <input type="checkbox" id="subscribers-mail-notifications" v-model="user.subscribers_mail_notifications"/>
                <label for="subscribers-mail-notifications" class="form-label">Subscribers mail notifications</label>
              </div>
              <div class="mb-3">
                <input type="checkbox" id="comments-mail-notifications" v-model=" user.comments_mail_notifications"/>
                <label for="comments-mail-notifications" class="form-label">Comments mail notifications</label>
              </div>
              <div class="mb-3">
                <input type="checkbox" id="weekly-statistics-mail-notifications" v-model="user.weekly_statistics_mail_notifications"
                       />
                <label for="weekly-statistics-mail-notifications" class="form-label">Weekly statistics mail notifications</label>
              </div>
              <div class="mb-3">
                <input type="checkbox" id="new-posts-mail-notifications" v-model="user.new_posts_mail_notifications"/>
                <label for="new-posts-mail-notifications" class="form-label">New posts mail notifications</label>
              </div>
              <div class="mb-3">
                <input type="checkbox" id="follow-ups-mail-notifications" v-model="user.follow_ups_mail_notifications"/>
                <label for="follow-ups-mail-notifications" class="form-label">Follow ups mail notifications</label>
              </div>
              <div class="mb-3">
                <input type="checkbox" id="chat-messages=mail-notifications" v-model="user.chat_messages_mail_notifications"/>
                <label for="chat-messages=mail-notifications" class="form-label">Chat messages mail notifications</label>
              </div>
              <div class="mb-3">
                <input type="checkbox" id="replies-mail-notifications" v-model="user.replies_mail_notifications"/>
                <label for="replies-mail-notifications" class="form-label">Replies mail notifications</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer justify-content-center d-flex gap-1">
        <button type="submit" class="btn btn-primary" :disabled="disableMail">
          <span v-if="!disableMail">Submit</span>
          <span v-else><font-awesome-icon icon="fa-solid fa-sync" spin/></span>
        </button>
      </div>
    </div>
    </form>
  </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Notyf } from 'notyf';
import Swal from 'sweetalert2'
import 'notyf/notyf.min.css';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import Notifications from "@/components/header/notifications.vue"; // for React, Vue and Svelte
export default  {
  components: {Notifications, FontAwesomeIcon},
  data() {
    return {
      user: {},
      validationErrors: [],
      loading: true,
      disableGenerate: false,
      disableSend: false,
      disableEditUser: false,
      disableMail: false,
      successEditUser: '',
      successEditMail: '',
      successForgotten: '',
      password: '',
      passwordRestore: ''
    }
  },
  methods: {
    fetchUser() {
      this.loading = true;
      axios.get(`/admin/users/${this.$route.params.id}`)
          .then(response => {
            this.user = response.data;
            this.initMailNotificationDefault();
            this.loading = false;
          })
          .catch(error => {
            this.loading = false;
            console.log(error);
          });
    },
    updateUser() {
      this.validationErrors = [];
      this.successEditUser = '';
      this.successEditMail = '';
      this.successForgotten = '';
      axios.put(`admin/users/${this.$route.params.id}`, this.user)
          .then(response => {
            this.successEditUser = response.data.message;
            setTimeout(() => this.successEditUser = '', 30000);
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.validationErrors = error.response.data.errors;
            }
          });
    },
   async restorePassword () {
      const confirmed = await Swal.fire({
        title: 'Confirmation',
        text: 'Are you sure you want to perform this operation?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, proceed',
        cancelButtonText: 'Cancel'
      });
      if(confirmed.isConfirmed) {
        this.validationErrors = [];
        this.passwordRestore = '';
        this.successEditUser = '';
        this.successForgotten = '';
        this.password = '';
        this.disableGenerate = true;
        await axios.post(`/admin/restore-password`, {email: this.user.email})
            .then(response => {
              this.passwordRestore = response.data.message;
              this.password = response.data.password;
              this.disableGenerate = false;
              setTimeout(() => this.passwordRestore = '', 60000);
              setTimeout(() => this.password = '', 60000);
            })
            .catch(error => {
              if (error.response.status === 422) {
                this.validationErrors = error.response.data.errors;
              }
              this.disableGenerate = false;
            });
      }
    },
   async sendForgottenPassword() {
      const confirmed = await Swal.fire({
        title: 'Confirmation',
        text: 'Are you sure you want to perform this operation?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, proceed',
        cancelButtonText: 'Cancel'
      });
      if (confirmed.isConfirmed) {
        this.validationErrors = [];
        this.successForgotten = '';
        this.passwordRestore = '';
        this.successEditUser = '';
        this.disableSend = true;
        axios.post(`/auth/forgot`, {email: this.user.email})
            .then(response => {
              this.successForgotten = response.data.message;
              this.disableSend = false;
              setTimeout(() => this.successForgotten = '', 60000);
            })
            .catch(error => {
              if (error.response.status === 422) {
                this.validationErrors = error.response.data.errors;
              }
              this.disableSend = false;
            });
      }
    },
    copyToClipboard() {
      const notyf = new Notyf();
      var tempInput = document.createElement('input');
      document.body.append(tempInput);
      tempInput.value = this.password;
      tempInput.select();
      document.execCommand('copy');
      notyf.success({
        message: 'Password has been copied',
        duration: 9000,
        icon: true,
      })
    },
    updateMailNotifications() {
      this.successEditMail = '';
      this.disableMail = true;
      axios.put(`admin/user/${this.$route.params.id}/mail-notifications`, {
          subscribers_mail_notifications: this.user.subscribers_mail_notifications,
          weekly_statistics_mail_notifications: this.user.weekly_statistics_mail_notifications,
          new_posts_mail_notifications: this.user.new_posts_mail_notifications,
          follow_ups_mail_notifications: this.user.follow_ups_mail_notifications,
          chat_messages_mail_notifications:  this.user.chat_messages_mail_notifications,
          comments_mail_notifications: this.user.comments_mail_notifications,
          replies_mail_notifications: this.user.replies_mail_notifications
      })
          .then(response => {
              this.successEditMail = response.data.message;
              this.disableMail = false;
              setTimeout(() => this.successEditMail = '', 60000);
          })
          .catch(error => {
            this.disableMail = false;
            console.log(error);
          })
    },
    initMailNotificationDefault() {
      if (this.user.subscribers_mail_notifications === null) {
        this.user.subscribers_mail_notifications = true;
      }
      if (this.user.comments_mail_notifications === null) {
        this.user.comments_mail_notifications = true;
      }
      if (this.user.new_posts_mail_notifications === null) {
        this.user.new_posts_mail_notifications = true;
      }
      if (this.user.weekly_statistics_mail_notifications === null) {
        this.user.weekly_statistics_mail_notifications= true;
      }
      if (this.user.follow_ups_mail_notifications=== null) {
        this.user.follow_ups_mail_notifications = true;
      }
      if (this.user.chat_messages_mail_notifications === null) {
        this.user.chat_messages_mail_notifications= true;
      }
      if (this.user.replies_mail_notifications === null) {
        this.user.replies_mail_notifications = true;
      }
    }
  },
    mounted() {
      window.scrollTo(0, 0);
      this.fetchUser();
    },
}
</script>

<style>
.gaps-accordion .mb-3 input {

  margin-right: 6px;

}

</style>