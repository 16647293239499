<template>
    <br/>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
        <label for="search" class="form-label mt-2 me-2">Keyword</label>
        <input type="text" id="search" class="form-control" placeholder="Name or creator" v-model="keyword"
               @input="searchKeyword"/>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
        <label for="per-page" class="form-label me-1 mt-2">Status</label>
        <select id="per-page" class="form-control" v-model="status" @click="changeStatus(status)">
          <option value="" :value="status">All</option>
          <option value="paid" :value="status">Paid</option>
          <option value="pending" :value="status">Pending</option>
          <option value="in_transit" :value="status">In transit</option>
          <option value="canceled" :value="status">Canceled</option>
          <option value="failed" :value="status">Failed</option>
        </select>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
        <label for="per-page" class="form-label me-1 mt-2">Per page</label>
        <select id="per-page" class="form-control" v-model="perPage" @click="perPageFilter(perPage)">
          <option value="10" :value="perPage">10</option>
          <option value="20" :value="perPage">20</option>
          <option value="50" :value="perPage">50</option>
        </select>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
        <label for="from-date" class="form-label mt-2 me-2">From date</label>
        <input type="datetime-local" id="from-date" class="form-control" placeholder="Name or creator" v-model="fromDate" @input="customDate"/>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
        <label for="to-date" class="form-label mt-2 me-2">To date</label>
        <div class="d-flex gap-1">
          <input type="datetime-local" id="to-date" class="form-control" v-model="toDate" @input="customDate"/>
          <button type="button" class="btn btn-dark-purple" @click="searchDate">
            <font-awesome-icon :icon="['fas', 'magnifying-glass']"/>
          </button>
        </div>
      </div>
      <div class="table-responsive container-fluid">
        <table class="table">
          <thead>
          <tr>
            <th scope="col"># ID</th>
            <th scope="col">Date</th>
            <th scope="col">Status</th>
            <th scope="col">Full name, email</th>
            <th scope="col">Creator</th>
            <th scope="col">Amount</th>
            <th scope="col">Estimated arrival date</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="payouts.length > 0" v-for="payout in payouts">
            <th scope="row">{{ payout.id }}</th>
            <td>{{ payout.created_at }}</td>
            <td style="text-transform: capitalize">{{ payout.status }}</td>
            <td>
              <p class="font-weight-bold">{{ payout.user.name }}</p>
              <a href="mailto:{{payout.user.email}}">{{ payout.user.email }}</a>
            </td>
            <td>
              <router-link v-if="payout.channel_id" :to="{name: 'SingleChannel',params: {id: payout.channel_id}}" target="_blank">
                {{payout.channel.name}}
              </router-link>
              <p v-else>-</p>
            </td>
            <td>
                <p>{{ payout.amount }} {{ payout.currency }}</p>
            </td>
            <td>{{ payout.estimated_arrival_date }}</td>
          </tr>
          <tr v-else-if="loading">
            <td colspan="8" class="text-center"><font-awesome-icon icon="fa-solid fa-sync fa-10x" spin/></td>
          </tr>
          <tr v-else>
            <td colspan="8" class="text-center">No payouts</td>
          </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-between" v-if="totalRecords >= 1">
          <div>
            <div class="p-2">
              <p>Total payouts: {{ totalRecords }}</p>
            </div>
          </div>
          <div>
            <div class="p-2">
              <nav v-if="totalPages >= 1" aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item" :class="{disabled: currentPage - 1 < 1}">
                    <a @click="paginate(currentPage - 1)" class="page-link">Previous</a>
                  </li>
                  <li class="page-item" :class="{active: 1 === currentPage}">
                    <a @click="paginate(1)" class="page-link" :class="{'not-clickable': currentPage === 1}">1</a>
                  </li>
                  <li class="page-item" v-if="currentPage - 2 > 1">
                    <a href="#" class="page-link">...</a>
                  </li>
                  <li class="page-item" v-if="currentPage - 2 > 1">
                    <a @click="paginate(currentPage - 2)" class="page-link">{{ currentPage - 2 }}</a>
                  </li>
                  <li class="page-item" v-if="currentPage - 1 > 1">
                    <a @click="paginate(currentPage - 1)" class="page-link">{{ currentPage - 1 }}</a>
                  </li>
                  <li class="page-item" v-if="currentPage !== 1" :class="{active: currentPage}">
                    <a @click="paginate(currentPage)" class="page-link"
                       :class="{'not-clickable': currentPage}">{{ currentPage }}</a>
                  </li>
                  <li class="page-item" v-if="currentPage + 1 <= totalPages">
                    <a @click="paginate(currentPage + 1)" class="page-link">{{ currentPage + 1 }}</a>
                  </li>
                  <li class="page-item" v-if="currentPage + 2 <= totalPages">
                    <a @click="paginate(currentPage + 2)" class="page-link">{{ currentPage + 2 }}</a>
                  </li>
                  <li class="page-item" v-if="currentPage + 2 < totalPages">
                    <a href="#" class="page-link">...</a>
                  </li>
                  <li class="page-item" v-if="currentPage !== totalPages && currentPage + 2 < totalPages"
                      :class="{active: totalPages === currentPage}">
                    <a @click="paginate(totalPages)" class="page-link"
                       :class="{'not-clickable': totalPages === currentPage}">{{ totalPages }}</a>
                  </li>
                  <li class="page-item" :class="{disabled: currentPage + 1 > totalPages}">
                    <a @click="paginate(currentPage + 1)" class="page-link">Next</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import axios from 'axios';
  import _debounce from "lodash/debounce";
  import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
  
  export default {
    components: {FontAwesomeIcon},
    data() {
      return {
        loading: true,
        status: '',
        keyword: '',
        fromDate: '',
        toDate: '',
        name: '',
        payouts: [],
        validationErrors: [],
        currentPage: 1,
        totalPages: 0,
        totalRecords: 0,
        perPage: 20
      }
    },
  
    mounted() {
        this.getPayouts('', '', '', '',1, 20);
    },
  
    methods: {
        debounceGetPayouts: _debounce(function (keyword, fromDate, toDate, status, page, perPage) {
            this.loading = true;
            axios.get(`/admin/payouts?keyword=${keyword}&status=${status}&from_date=${fromDate}&to_date=${toDate}&page=${page}&per_page=${perPage}`).then(response => {
                console.log(response);
                this.payouts = response.data.data;
                this.totalPages = response.data.totalPages;
                this.totalRecords = response.data.totalRecords;
                this.loading = false;
            })
            .catch(error => {
                console.log(error);
                this.loading = false;
            });
        }, 500),
        getPayouts(keyword, fromDate, toDate, status, page, perPage) {
            this.debounceGetPayouts(keyword, fromDate, toDate, status, page, perPage);
        },
        customDate(date) {
            let dateValue = this[date];

            // Check if the input is a valid date
            if (dateValue) {
                // Convert the input to a Date object
                const dateObject = new Date(dateValue);
                this[date] = this.formatDate(dateObject);
            }
        },
        searchDate() {
            this.getPayouts(this.keyword, this.fromDate, this.toDate, this.status, this.currentPage, this.perPage);
        },
        changeStatus(status) {
            this.status = status;
            this.getPayouts(this.keyword, this.fromDate, this.toDate,  this.status, this.currentPage, this.perPage);
        },
        searchKeyword() {
            this.getPayouts(this.keyword, this.fromDate, this.toDate,  this.status, this.currentPage, this.perPage);
        },
        perPageFilter(perPage) {
            this.perPage = perPage;
            this.getPayouts(this.keyword, this.fromDate, this.toDate,  this.status, this.currentPage, this.perPage);
        },
        paginate(page) {
            this.currentPage = page;
            this.getPayouts(this.keyword, this.fromDate, this.toDate,  this.status, this.currentPage, this.perPage);
        },
    }
  }
  </script>
  