<template>
    <div style="padding:5px"  class="row">
        <div class="table-responsive container-fluid">
            <table class="table">
                <thead>
                <tr>
                    <th scope="col"># ID</th>
                    <th scope="col">Channel name</th>
                    <th scope="col">RSS Link</th>
                    <th scope="col">Created at</th>
                    <th scope="col">Expiration date</th>
                </tr>
                </thead>
                <tbody>
                    <tr v-if="rss.length > 0" v-for="singleRss in rss">
                    <th scope="row">{{ singleRss.id }}</th>
                    <td><router-link v-if="singleRss.channel" :to="{name: 'SingleChannel',params: {id: singleRss.channel.id}}" target="_blank">
                        {{singleRss.channel.name}}
                        </router-link>
                        <p v-else>-</p>
                    </td>
                    <td><a target="_blank" :href="singleRss.rss_link">Click here</a></td>
                    <td>{{ singleRss.created_at }}</td>
                    <td>{{ singleRss.expiration_date }}</td>
                    </tr>
                    <tr v-else-if="loading">
                    <td colspan="8" class="text-center"><font-awesome-icon icon="fa-solid fa-sync fa-10x" spin/></td>
                    </tr>
                    <tr v-else>
                    <td colspan="8" class="text-center">No Rss</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
  
  
<script>
import axios from 'axios';
import _debounce from "lodash/debounce";

export default {
    data() {
        return {
            loading: true,
            rss: [],
            successMessage: '',
            errorMessage: '',
        }
    },
    mounted() {
        this.fetchUserRss();
    },
    methods: {
        fetchUserRss() {
            this.loading = true;
            axios.get(`/admin/rss/user/${this.$route.params.id}`)
            .then(response => {
                this.rss = response.data.data;
                this.loading = false;
                console.log(response.data);
            })
            .catch(error => {
                this.loading = false;
                console.log(error);
            });
        },
    }
}
</script>